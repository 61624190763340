const details = [
  {
    id: 54,
    name: 'Demo Online Shop',
    image: 'images/shop.png',
    link: 'https://pythononlineshopstripeapi-production.up.railway.app/',
    gitHub_link: 'https://github.com/vicmoon/Python_OnlineShop_StripeAPI',
    language: 'Python, API',
  },
  {
    id: 53,
    name: 'YouTube Search',
    image: 'images/youtube.png',
    link: 'https://pythonmyyoutube-production.up.railway.app/',
    gitHub_link: 'https://github.com/vicmoon/Python_MyYouTube',
    language: 'Python, API',
  },
  {
    id: 52,
    name: 'Spotify Search',
    image: 'images/podcast.png',
    link: 'https://pythonspotifyapishows-production.up.railway.app/',
    gitHub_link: 'https://github.com/vicmoon/Python_SpotifyAPI_Shows',
    language: 'Python, API',
  },
  {
    id: 51,
    name: 'Dinosaur Game',
    image: 'images/dino_game.png',
    link: 'https://pythonautodinasaurgame-production.up.railway.app/',
    gitHub_link: 'https://github.com/vicmoon/Python_AutoDinasaurGame',
    language: 'Python, JavaScript',
  },
  {
    id: 50,
    name: 'Extract RGB',
    image: 'images/colors.png',
    link: 'https://pythonextractcolors-production.up.railway.app/',
    gitHub_link: 'https://github.com/vicmoon/Python_ExtractColors',
    language: 'Python',
  },
  {
    id: 49,
    name: 'Text To Speech',
    image: 'images/recording.png',
    link: '#',
    gitHub_link: 'https://github.com/vicmoon/Python_TextToSpeech',
    language: 'Python',
  },
  {
    id: 48,
    name: 'Disappearing Txt',
    image: 'images/disappearing.png',
    link: 'https://pythondisappearingtext-production.up.railway.app/',
    gitHub_link: 'https://github.com/vicmoon/Python_DisappearingText',
    language: 'Python',
  },
  {
    id: 47,
    name: 'ToDo List',
    image: 'images/todo.png',
    link: 'https://pythongreattodolist-production.up.railway.app/',
    gitHub_link: 'https://github.com/vicmoon/Python_GreatToDoList',
    language: 'Python',
  },
  {
    id: 46,
    name: 'Cafe&Wifi',
    image: 'images/database.png',
    link: 'https://pythongoodcafesite-production.up.railway.app/',
    gitHub_link: 'https://github.com/vicmoon/Python_GoodCafe_Site',
    language: 'Python',
  },
  {
    id: 45,
    name: 'BreakOut!',
    image: 'images/breakout.png',
    link: '#',
    gitHub_link: 'https://github.com/vicmoon/Python_BreakOutGame',
    language: 'Python',
  },
  {
    id: 44,
    name: 'Speed Typing',
    image: 'images/speed_typing.png',
    link: 'https://pythontypespeedtest-production.up.railway.app/',
    gitHub_link: 'https://github.com/vicmoon/Python_TypeSpeedTest',
    language: 'Python',
  },
  {
    id: 43,
    name: 'Watermark App',
    image: 'images/watermark.png',
    link: '#',
    gitHub_link: 'https://github.com/vicmoon/Python_WatermarkingPhoto',
    language: 'Python',
  },
  {
    id: 42,
    name: 'Morse-Converter',
    image: 'images/morse_code.jpg',
    link: 'https://pythontexttomorsecode-production.up.railway.app/',
    gitHub_link: 'https://github.com/vicmoon/Python_Text_to_MorseCode',
    language: 'Python',
  },
  {
    id: 41,
    name: 'Demo App/Auth',
    image: 'images/flask_auth.jpg',
    link: 'https://pythonauthentification-production.up.railway.app/',
    gitHub_link: 'https://github.com/vicmoon/Python_Authentification',
    language: 'Python',
  },
  {
    id: 40,
    name: 'Demo Cafe API',
    image: 'images/demo_api.jpg',
    link: 'https://pythonrestapi-production.up.railway.app/',
    gitHub_link: 'https://github.com/vicmoon/Python_RESTAPI',
    language: 'Python',
  },
  {
    id: 39,
    name: 'Movies Library',
    image: 'images/movie_library.jpg',
    link: 'https://pythontopmovies-production.up.railway.app/',
    gitHub_link: 'https://github.com/vicmoon/Python_TopMovies',
    language: 'Python',
  },
  {
    id: 38,
    name: 'Reading List',
    image: 'images/reading_list.png',
    link: 'https://pythonsqliteandsqlalchemy-production.up.railway.app/',
    gitHub_link: 'https://github.com/vicmoon/Python_SQLiteandSQLAlchemy',
    language: 'Python',
  },
  {
    id: 37,
    name: 'Portfolio',
    image: 'images/blog.jpg',
    link: 'https://victoriamunteanuportfolio.net/home',
    gitHub_link: 'https://github.com/vicmoon/portfolio_react',
    language: 'JavaScript, React',
  },
  {
    id: 36,
    name: 'Contacts',
    image: 'images/card_name.png',
    link: 'https://pythonnamecard-production.up.railway.app/',
    gitHub_link: 'https://github.com/vicmoon/Python_Name_Card',
    language: 'Python',
  },
  // {
  //   id: 1,
  //   name: 'Quiz',
  //   image: 'images/quiz.png',
  //   link: 'https://pythonquizclasses-production.up.railway.app/',
  //   gitHub_link: 'https://github.com/vicmoon/Python_Quiz_Classes',
  //   language: 'Python',
  // },
  // {
  //   id: 2,
  //   name: 'Blackjack',
  //   image: 'images/blackjack.png',
  //   link: 'https://pythonblackjack-production.up.railway.app/',
  //   gitHub_link: 'https://github.com/vicmoon/Python_Blackjack',
  //   language: 'Python',
  // },
  {
    id: 3,
    name: 'Hangman',
    image: 'images/hangman.png',
    link: 'https://victoria.pythonanywhere.com/',
    gitHub_link: 'https://github.com/vicmoon/Python_Hangman',
    language: 'Python',
  },
  {
    id: 4,
    name: 'My Blog',
    image: 'images/site.jpg',
    link: 'https://www.bitsandscribbles.com/',
    gitHub_link: 'https://github.com/vicmoon/MyBlog3',
    language: 'Node js, Express, MongoDb, EJS',
  },
  // {
  //   id: 5,
  //   name: 'Disappearing Notifications',
  //   image: 'images/toastMessages.png',
  //   link: 'https://disappearingnotifications.netlify.app/',
  //   language: 'JavaScript, CSS, HTML',
  // },
  // {
  //   id: 6,
  //   name: 'Vertical Slider',
  //   image: 'images/verticalSlider.png',
  //   link: 'https://simpleverticalslider.netlify.app/',
  //   language: 'JavaScript, CSS, HTML',
  // },
  {
    id: 7,
    name: 'Greenish',
    image: 'images/greenish.png',
    link: 'https://greenishworld.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/greenish',
    language: 'JavaScript, Phaser',
  },
  // {
  //   id: 8,
  //   name: 'Simple Drawing Pad',
  //   image: 'images/drawing.png',
  //   link: 'https://simpledrawingpad.netlify.app/',
  //   language: 'JavaScript, CSS, HTML',
  // },
  {
    id: 9,
    name: 'Drink',
    image: 'images/water.png',
    link: 'https://howmuchwater.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/DrinkWater',
    language: 'JavaScript, CSS, HTML',
  },
  {
    id: 10,
    name: 'Choice',
    image: 'images/choices.png',
    link: 'https://randomchoices.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/ChoicePicker',
    language: 'JavaScript, CSS, HTML',
  },
  {
    id: 11,
    name: 'Cards',
    image: 'images/faq.png',
    link: 'https://servicenow-faqs.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/FAQ_Toggle',
    language: 'JavaScript, CSS, HTML',
  },
  {
    id: 12,
    name: 'Key Events',
    image: 'images/keycodes.png',
    link: 'https://eventkeycodesok.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/EventKeyCodes',
    language: 'JavaScript, CSS, HTML',
  },
  {
    id: 13,
    name: '😆 Jokes',
    image: 'images/joke.png',
    link: 'https://sillydadjokes.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/DadJockes',
    language: 'JavaScript, CSS, HTML',
  },
  {
    id: 14,
    name: '🍎 Catcher',
    image: 'images/apple_catcher.png',
    link: 'https://superlative-shortbread-44dd7e.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/Phaser_Apple_Catcher',
    language: 'JavaScript, Phaser',
  },
  {
    id: 15,
    name: '🃏 Cards',
    image: 'images/flash.png',
    link: 'https://bejewelled-unicorn-e13d98.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/React_ServiceNow_Cards',
    language: 'JavaScript, CSS, React, API',
  },
  // {
  //   id: 16,
  //   name: '👀 Minion 👀',
  //   image: 'images/minion.png',
  //   link: 'https://steady-tiramisu-8d8866.netlify.app/',
  //   language: 'JavaScript, CSS',
  // },
  {
    id: 17,
    name: '🔮Magic',
    image: 'images/magic.png',
    link: 'https://timely-fox-b010dc.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/JavaScript_MagicEightBall',
    language: 'DOM, JavaScript, CSS',
  },
  {
    id: 18,
    name: '🍪 Pac-Man',
    image: 'images/pacman.png',
    link: 'https://timely-cranachan-0d6ab6.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/JavaScript_Pac_Man',
    language: 'DOM, JavaScript, CSS',
  },
  {
    id: 19,
    name: '🐍 Snake',
    image: 'images/snake.png',
    link: 'https://fluffy-pudding-0f0eac.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/JavaScript_SnakeGame',
    language: 'DOM, JavaScript, CSS',
  },
  {
    id: 20,
    name: '🔠 Guess',
    image: 'images/guesstheword.png',
    link: 'https://tourmaline-cajeta-b0d8d8.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/JavaScript_GuessTheWord',
    language: 'DOM, JavaScript',
  },
  {
    id: 21,
    name: '🗺️ Map ',
    image: 'images/map.png',
    link: 'https://effervescent-travesseiro-b371e9.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/JavaScript__MaptyAPP_Geolocation',
    language: 'JavaScript, Leaflet, API, Geolocation',
  },
  // {
  //   id: 22,
  //   name: '💰 Fictive Bank Site',
  //   image: 'images/banking.jpg',
  //   link: 'https://stirring-kashata-29068c.netlify.app/',
  //   language:
  //     'Advanced DOM manipulation, Slide element, Sticky Navigation, DOM events, IntersectionObserver, JavaScript, CSS',
  // },
  {
    id: 23,
    name: '🕹️ My Games',
    image: 'images/games_board.PNG',
    link: 'https://mygamesboard.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/JavaScript_Functions_Demo',
    language: 'DOM manipulation, JavaScript, CSS',
  },
  {
    id: 24,
    name: '❌ Tic-Tac-Toe',
    image: 'images/tictactoe.png',
    link: 'https://snazzy-speculoos-db3893.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/JavaScript_TicTacToe',
    language: 'DOM manipulation, JavaScript, CSS',
  },
  {
    id: 25,
    name: '💵 Banking',
    image: 'images/fictive_bank.png',
    link: 'https://clinquant-cajeta-42eed6.netlify.app/',
    gitHub_link: '',
    language: 'DOM manipulation, JavaScript, CSS',
  },
  {
    id: 26,
    name: '🤯 Memory Game',
    image: 'images/memory1.png',
    link: 'https://vermillion-tapioca-55e5ed.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/JavaScript_MemoryGame',
    language: 'DOM manipulation, JavaScript, CSS',
  },
  {
    id: 27,
    name: '🧩 Puzzle',
    image: 'images/sponge.png',
    link: 'https://extraordinary-peony-cd46ff.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/JavaScript_SlidePuzzleGame',
    language: 'DOM manipulation, JavaScript, CSS',
  },
  {
    id: 28,
    name: '🎲Roll it',
    image: 'images/pig-game.png',
    link: 'https://ornate-halva-c5686c.netlify.app/',
    gitHub_link: '',
    language: 'DOM Class manipulation, JavaScript, CSS',
  },
  {
    id: 29,
    name: '🔢 Guess',
    image: 'images/Guess_the_number.png',
    link: 'https://soft-pika-ed6b48.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/JavaScript_GuessTheWord',
    language: 'DOM manipulation, JavaScript',
  },
  // {
  //   id: 30,
  //   name: 'Site Template',
  //   image: 'images/extraterestrial.png',
  //   link: 'https://vicmoon.github.io/CSS_SITE/',
  //   language: 'CSS, HTML, Responsive Template',
  // },
  {
    id: 31,
    name: 'Rock-Paper-Scissors',
    image: 'images/game.png',
    link: 'https://vicmoon.github.io/Javascript_Mainproject/',
    gitHub_link: '',
    language: 'CSS, HTML, JavaScript Game',
  },
  {
    id: 32,
    name: 'Dictionary',
    image: 'images/Dictionary.JPG',
    link: 'https://inspiring-tanuki-a897c7.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/my-dictionary',
    language: 'CSS, API, HTML, JavaScript, React',
  },
  {
    id: 33,
    name: 'World Clock',
    image: 'images/clock.JPG',
    link: 'https://phenomenal-unicorn-f160f9.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/WorldClock',
    language: 'CSS, HTML, JavaScript, API, Responsive, moment.js',
  },
  {
    id: 34,
    name: 'Weather-React',
    image: 'images/weather.JPG',
    link: 'https://illustrious-beignet-228d77.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/react-my-weather',
    language: 'React, CSS, HTML',
  },
  {
    id: 35,
    name: 'Weather-JavaScript',
    image: 'images/vanilajs.JPG',
    link: 'https://vanillaweathersearch.netlify.app/',
    gitHub_link: 'https://github.com/vicmoon/Vanilla-Weather',
    language: 'JavaScript',
  },
];

export default details;
